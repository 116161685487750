<template>
  <div
      :class="wrapperClass"
      tabindex="0"
      role="checkbox"
      :aria-labelledby="`label-${props.id}`"
      :aria-checked="props.checked"
      @click="handleClick"
  >
    <span :class="iconClass">
      <span class="uncheck" v-if="!props.checked"></span>
      <span class="check" v-if="props.checked"></span>
    </span>
    <span :id="`label-${props.id}`" class="label">
      {{ props.label }}
    </span>
  </div>
</template>

<script setup>
import { computed } from "vue";

const emit = defineEmits({
  onChange: {
    type: "change",
    default: () => {},
  },
});

const props = defineProps({
  value: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  checked: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const wrapperClass = computed(() => {
  const { checked } = props;
  return {
    "check-box": true,
    "check-box--checked": checked,
  };
});

const iconClass = computed(() => {
  const { checked } = props;
  return {
    "check-box__icon": true,
    "check-box__icon--checked": checked,
  };
});

const handleClick = () => {
  emit("onChange", props.id);
};
</script>

<style scoped lang="scss">
@import "../assets/scss/main";
.check-box {
  align-items: center;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding: 0.3rem;
  user-select: none;
}

.label {
  padding-left: 2rem;
}

.check-box__icon {
  display: block;
  height: 1rem;
  width: 1rem;
}

.check-box--checked {
  width: 4px;
  height: 4px;
  border: 1px solid transparent;
  background-color: $theme-primary !important;
  border-radius: 4px;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: $theme-primary-light !important;
  }
}

.check-box {
  width: 4px;
  height: 4px;
  border: 1px solid $theme-primary;
  background-color: $theme-white;
  border-radius: 4px;
  transition: all 0.1s ease-in-out;

  &:hover {
    outline: 1px solid $theme-primary;
  }
}
</style>
